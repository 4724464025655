export const API_URL = "https://lpi-connect-24a87b02d8d3.herokuapp.com";
// export const API_URL = "https://lpi-connect-preprod-d5be5378d3df.herokuapp.com";
// export const API_URL = "http://localhost:3000";

export const COOKIE_TOKEN = "_lpi_token_session";
export const COOKIE_ID = "_lpi_id_session";

export const REGEX_MAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LOGIN_URI = "/auth/login";
export const PURCHASE_ORDERS_URI = "/purchase_orders";
export const SIMULATE = "/simulate";
export const PROFILE = "/users/";
export const UPDATE_STATUS = "/update_status";
export const GET_HISTORY = PURCHASE_ORDERS_URI;
export const DASHBOARD = "/dashboard";
export const USERS = PROFILE;
export const APPROVED_BY_ADMIN = "/update_by_admin/";
export const UPDATE_USER = PROFILE;
export const SIGNIN = "/users";
export const PREPARE_ORDER = "/prepare_order";
export const ORDERS = "/orders/";
export const FINISH_ORDER = "/finish_order";
export const PLACE_ORDER = "/place_order";
